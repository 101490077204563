import {Pipe, PipeTransform} from '@angular/core';
import {FileTypeUtil} from '../classes/file-type.util';

@Pipe({
    name: 'assetFileType',
    standalone: false
})
export class AssetFileTypePipe implements PipeTransform {

    private static privatePipe = new AssetFileTypePipe();

    public static format(value?: string): string {
        return AssetFileTypePipe.privatePipe.transform(value);
    }

    transform(assetExtension: string): string {
        return FileTypeUtil.getAssetFileType(assetExtension);
    }
}

import {UserModel} from '../../models/api/user.model';

export const SEGMENT_SERVICE = 'SEGMENT_SERVICE';

export enum ESegmentTrackEvents {
    TRACK_REMOVE_PRODUCT = 'Remove Product',
    TRACK_ADD_CAMPAIGN = 'Add Campaign',
    TRACK_REMOVE_CAMPAIGN = 'Remove Campaign',
    TRACK_EDIT_CAMPAIGN = 'Edit Campaign',
    TRACK_REMOVE_ASSET = 'Remove Asset',
    TRACK_REMOVE_PROJECT = 'Remove Project',
}

export interface ISegmentService {
    identify(): void;
    reset(): void;
    page(category?: string, name?: string, properties?: any, options?: any): void;
    track(event: string, properties?: any, options?: any): void;
    setUser(user: UserModel): void;
}
